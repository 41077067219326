import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer style={{
    marginTop: `2rem`
  }}>
    © {new Date().getFullYear()},
    {` `}
    <Link to="/">
      {siteTitle}
    </Link>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
